<template>
  <div class="allocate-lawyers">
    <div class="container fluid">
      <update-lawyer-allocation type="agency" :projectId="$route.params.id">
      </update-lawyer-allocation>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    UpdateLawyerAllocation: () =>
      import(
        "@/modules/Project/components/AssignLawyers/UpdateProjectAllocation"
      )
  },
  mixins: [],
  props: {},
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss"></style>
